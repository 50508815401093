import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { showLoader, isUserAuthenticated } from '../auxiliares/funciones';

class Pages404 extends Component {

    componentDidMount() {
        showLoader(false)
    }

    render() {
        return (
            <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" lg="6" xl="5">
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">

                                    <div className="ex-page-content text-center">
                                        <h1 className="text-dark">404!</h1>
                                        <h3 className="">La url a la que intentas acceder no es válida</h3>
                                        <br/>
                                        {isUserAuthenticated() ? <Link className="btn btn-info mb-4 waves-effect waves-light" to="/app"><i className="mdi mdi-home"></i> Ir a la página principal</Link>
                                        : <Link className="btn btn-info mb-4 waves-effect waves-light" to="/login"><i className="mdi mdi-home"></i> Ir a la página principal</Link>}
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Pages404);
