import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from "react-redux";

import { authProtectedRoutes, publicRoutes } from "./routes/";
import { isUserAuthenticated, getProfile } from './auxiliares/funciones';
import AppRoute from "./routes/route";

import PrincipalLayout from "./plantillas/principal/";
import LoginLayout from "./plantillas/login";

// Import scss
import "./theme.scss";

import Page404 from "./paginas/404";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let profileID = 0
    if(isUserAuthenticated()){
      profileID = getProfile()
    }

    return (
      <React.Fragment>
        <Router>
          <Switch>

          {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                component={route.component}
                layout={LoginLayout}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.filter(route => route.profiles.includes(profileID) ).map((route, idx) => {
              return route.exact !== undefined ?
              <AppRoute
                path={route.path}
                component={route.component}
                layout={PrincipalLayout}
                key={idx}
                isAuthProtected={true}
                exact
              />
              :<AppRoute
                path={route.path}
                component={route.component}
                layout={PrincipalLayout}
                key={idx}
                isAuthProtected={true}
              />
            })}
            {isUserAuthenticated() ? <AppRoute layout={PrincipalLayout} component={Page404} />
            : <AppRoute layout={LoginLayout} component={Page404} /> }
            {/*<Redirect to="/404" />*/}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
